import { Link } from "gatsby";
import React from "react";

type BlueButtonProps = {
  text: string;
  anchor: string;
  minimal?: boolean;
};

const BlueButton = (props: BlueButtonProps) => {
  return (
    <Link
      to={props.anchor}
      className={
        "inline-block text-center font-semibold bg-blue-700 hover:bg-blue-800 text-white " +
        (props.minimal ? "py-2 px-4 text-sm rounded" : "py-4 px-10 text-base rounded-lg")
      }
    >
      {props.text}
    </Link>
  );
};

export default BlueButton;
